import { AuthContext } from '../core/AuthContext'
import React, {
  ClipboardEvent,
  FunctionComponent,
  memo,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Editor, EventHook, getEventTransfer, setEventTransfer } from 'slate-react'
import EditorContainer from '../components/EditorContainer'
import { WithErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary.component'
import { FormatingPlugin, Toolbar } from '../core/FormatingPlugin'
import { createOnPaste } from '../core/HtmlSerializer'
import { ImagePlugin } from '../core/ImagePlugin'
import { EditorCommands, EditorMode, EditorValue, schema } from '../core/model/TextEditor.model'
import { getEditorStyles } from '../core/TextEditor.style'
import { listVariables, updateVariableFixedValue } from '../core/TextEditor.utilities'
import { VariablePlugin } from './VariablePlugin'
import { ContextualMenu } from './VariablePlugin/components/ContextualMenu'
import { VariableToolbar } from './VariableToolbarItems.component'
import {
  DisplayContextualMenuConfig,
  VariableCreatedCallback,
  WithVariableEditorProps,
  ClickVariableCallback,
} from './WithVariableEditor.model'
import {
  DEFAULT_WITH_VARIABLE_EDITOR_CONTEXT,
  VariableType,
  VariablesData,
  WithVariableEditorContext,
} from './WithVariableEditorContext'
import { ClassNames } from '@emotion/react'

const plugins = (readOnly: boolean, mode?: EditorMode) => [
  FormatingPlugin(),
  VariablePlugin(),
  ImagePlugin({ mode: mode === 'edit' ? 'edit' : 'view', readOnly }),
]

const defaultPasteHandler = createOnPaste()

export const WithVariableEditor: FunctionComponent<WithVariableEditorProps> =
  WithErrorBoundary<WithVariableEditorProps>(
    ({
      disabled = false,
      readOnly = false,
      value,
      mode = 'preview',
      variableData,
      deactivateSchemaValidation = false,
      enableVariableFixedValues = false,
      toolbarConfig,
      onValueChange,
      onClickVariable,
      onVariableSecondaryAction,
      onPaste,
      renderVariableToolbarContent,
      renderVariableContextualContent,
      toolbarAdditionalContent,
      variableFallbackTheme,
      editorBanner,
      toolbarStyle,
      saveAsText,
      onAddImage,
      token,
      usurpedUser,
    }) => {
      const editorRef = useRef<Editor>(null)

      const [contextualMenuConfig, setContextualMenuConfig] = useState<
        DisplayContextualMenuConfig | undefined
      >(undefined)

      const handleChange = ({ value }: { value: EditorValue }) => {
        if (onValueChange) {
          onValueChange(value)
        }
      }

      const memoizedPlugins = useMemo(() => plugins(readOnly, mode), [readOnly, mode])

      const handlePaste: EventHook<React.ClipboardEvent> = (event, editor, next) => {
        if (onPaste) {
          const transfer = getEventTransfer(event) as any
          if (transfer.fragment) {
            const fragmentValue = new EditorValue({ document: transfer.fragment })
            const variables = listVariables(fragmentValue.toJSON(), variableData)
            onPaste(variables)
          }
        }
        defaultPasteHandler(event, editor, next)
      }

      const makeCreateVariableHandler = (editor: Editor): VariableCreatedCallback => {
        return (newVariable) => {
          editor.command(EditorCommands.INSERT_VARIABLE, newVariable)
        }
      }

      const clickVariable: ClickVariableCallback = (properties) => {
        if (onClickVariable) {
          onClickVariable(properties)
        }
      }

      const variableSecondaryAction: ClickVariableCallback = (properties) => {
        if (onVariableSecondaryAction) {
          onVariableSecondaryAction(properties)
        }
      }

      const handleCopy = (event: ClipboardEvent, editor: Editor, next: () => any) => {
        setEventTransfer(event, 'fragment', editor.value.fragment)
        return next()
      }

      const handleUpdateVariableFixedValue = (
        slateKey: string,
        variableId: string,
        variableValue: VariableType,
      ) => {
        handleChange({
          value: updateVariableFixedValue(value, slateKey, variableId, variableValue),
        })
      }

      const handleDisplayMenu = useCallback(
        (contextualMenuConfig?: DisplayContextualMenuConfig) => {
          setContextualMenuConfig(contextualMenuConfig)
        },
        [],
      )

      const writeFixedValues = !readOnly && !disabled && enableVariableFixedValues

      return (
        <AuthContext.Provider value={{ token, usurpedUser }}>
          <WithVariableEditorContext.Provider
            value={{
              ...DEFAULT_WITH_VARIABLE_EDITOR_CONTEXT,
              variableData,
              mode,
              enableVariableFixedValues: writeFixedValues,
              variableFallbackTheme,
              clickVariable,
              variableSecondaryAction,
              displayContextualMenu: handleDisplayMenu,
              updateVariableFixedValue: handleUpdateVariableFixedValue,
            }}
          >
            <EditorContainer mode={mode} disabled={disabled}>
              {editorRef.current && mode !== 'print' && !readOnly && (
                <Toolbar
                  editor={editorRef.current}
                  value={value}
                  config={toolbarConfig}
                  readOnly={readOnly}
                  editorBanner={editorBanner}
                  style={toolbarStyle}
                  saveAsText={saveAsText}
                  onAddImage={onAddImage}
                >
                  {mode === 'edit' && renderVariableToolbarContent && (
                    <VariableToolbar
                      onCreateVariable={makeCreateVariableHandler(editorRef.current)}
                      renderContent={renderVariableToolbarContent}
                    />
                  )}

                  {toolbarAdditionalContent}
                </Toolbar>
              )}
              {mode === 'edit' && (
                <ContextualMenu
                  displayed={!!contextualMenuConfig}
                  top={contextualMenuConfig && contextualMenuConfig.position.top}
                  left={contextualMenuConfig && contextualMenuConfig.position.left}
                  offsetTop={15}
                  renderContent={(hide) =>
                    renderVariableContextualContent &&
                    contextualMenuConfig &&
                    renderVariableContextualContent(
                      contextualMenuConfig.variableSlateNodeKey,
                      contextualMenuConfig.variableContext,
                      contextualMenuConfig.variableId,
                      hide,
                    )
                  }
                />
              )}
              <ClassNames>
                {({ css }) => (
                  <Editor
                    readOnly={readOnly || mode === 'print'}
                    ref={editorRef}
                    value={value}
                    onChange={handleChange}
                    onCopy={handleCopy}
                    onPaste={handlePaste}
                    schema={!deactivateSchemaValidation ? schema : undefined}
                    plugins={memoizedPlugins}
                    autoFocus={!(readOnly || disabled)}
                    className={css(getEditorStyles(mode, readOnly || disabled))}
                  />
                )}
              </ClassNames>
            </EditorContainer>
          </WithVariableEditorContext.Provider>
        </AuthContext.Provider>
      )
    },
  )

function nothing(..._attr: any[]) {}

export function getWithVariableHtml(
  value: EditorValue,
  data: VariablesData,
  token?: string,
): string {
  return renderToStaticMarkup(
    <WithVariableEditor
      mode="print"
      value={value}
      variableData={data}
      onValueChange={nothing}
      token={token}
    />,
  )
}

export const WithVariableValueToHtml = memo<{
  value: EditorValue
  data: VariablesData
  token?: string
}>(({ value, data, token }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: getWithVariableHtml(value, data, token),
    }}
  />
))
