import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  ReactNode,
  useState,
  useCallback,
} from 'react'
import ReactDOM from 'react-dom'
import useOnClickOutsideElement from '../../../../hooks/useClickOutsideElement.hook'
import useIsomorphicLayoutEffect from '../../../../hooks/useIsomorphicLayoutEffect.hook'
import { WithVariableEditorContext } from '../../../../WithVariableEditor/WithVariableEditorContext'
import { detectOverflow, calculatePosition } from './position.utils'

const showIt = keyframes`
  0% {
    transform: matrix(.97,0,0,1,0,12);
    opacity: 0
  }

  20% {
    transform: matrix(.99,0,0,1,0,2);
    opacity: .7
  }

  40% {
    transform: matrix(1,0,0,1,0,-1);
    opacity: 1
  }

  70% {
    transform: matrix(1,0,0,1,0,0);
    opacity: 1
  }

  100% {
    transform: matrix(1,0,0,1,0,0);
    opacity: 1
  }
`

const ContextualMenuContainer = styled.div<ContextualMenuContainerProps>`
  opacity: 0;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  z-index: 1000;
  box-shadow:
    0 8px 16px 0 rgba(0, 0, 0, 0.2),
    0 2.4px 5.1px 0 rgba(0, 0, 0, 0.13),
    0 1px 2px 0 rgba(0, 0, 0, 0.1),
    0 0.4px 0.8px 0 rgba(0, 0, 0, 0.07);
  max-height: 430px;
  overflow-y: scroll;
  overflow-x: hidden;

  display: ${({ displayed }) => (displayed ? 'block' : 'none')};

  ${({ displayed }) =>
    displayed &&
    css`
      animation: ${showIt} 180ms forwards linear;
    `}
`

interface HiddenHoverMenuProps {
  displayed: boolean
  top?: number
  left?: number
  offsetTop?: number
  offsetLeft?: number
  renderContent: (hide: () => void) => ReactNode
}
interface DisplayedHoverMenuProps {
  displayed: boolean
  top: number
  left: number
  offsetTop: number
  offsetLeft: number
  renderContent: (hide: () => void) => ReactNode
}
export const ContextualMenu: FunctionComponent<HiddenHoverMenuProps | DisplayedHoverMenuProps> = ({
  displayed,
  top,
  left,
  offsetTop = 0,
  offsetLeft = 0,
  renderContent,
}) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const { displayContextualMenu } = useContext(WithVariableEditorContext)

  const [calculatedTop, setCalculatedTop] = useState<number>()
  const [calculatedLeft, setCalculatedLeft] = useState<number>()

  useEffect(() => {
    if (!displayed && menuRef.current) {
      menuRef.current.removeAttribute('style')
    }
  }, [displayed])

  /**
   * Calcul du positionnement du menu
   */
  useEffect(() => {
    if (menuRef.current && top && left) {
      const { top: topToSet, left: leftToSet } = calculatePosition(
        menuRef.current,
        { top, left },
        { top: offsetTop, left: offsetLeft },
      )
      setCalculatedTop(topToSet)
      setCalculatedLeft(leftToSet)
    }
  }, [top, offsetTop, left, offsetLeft])

  /**
   * Adapation de l'emplacement horizontal du menu em fonction de sa largeur
   */
  useIsomorphicLayoutEffect(() => {
    if (menuRef.current && calculatedLeft) {
      const { left: overflowLeft, right: overflowRight } = detectOverflow(menuRef.current)
      if (overflowLeft > 0) {
        setCalculatedLeft(calculatedLeft + overflowLeft)
      } else if (overflowRight > 0) {
        setCalculatedLeft(calculatedLeft - overflowRight)
      }
    }
  }, [calculatedLeft])

  const hideMenu = useCallback(() => {
    displayContextualMenu()
  }, [displayContextualMenu])

  useOnClickOutsideElement(menuRef, hideMenu)

  const menuPositionStyle = { top: `${calculatedTop}px`, left: `${calculatedLeft}px` }
  const root = window.document.body
  return ReactDOM.createPortal(
    <ContextualMenuContainer
      displayed={displayed}
      style={menuPositionStyle}
      ref={menuRef}
      onMouseDown={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      {displayed && renderContent(hideMenu)}
    </ContextualMenuContainer>,
    root,
  )
}
